var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"PermissionGroup Component","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-dialog',{staticClass:"col-6 ml-auto mr-auto",attrs:{"width":"80%"},on:{"click:outside":_vm.cancelCreate,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancelCreate.apply(null, arguments)}},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{class:'headline primary pb-3 mb-3'},[_vm._v(" Create Permission Group ")]),_c('v-card-text',{staticClass:"my-2 py-2"},[_c('v-form',{ref:"form",model:{value:(_vm.validEdit),callback:function ($$v) {_vm.validEdit=$$v},expression:"validEdit"}},[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"autofocus":"","rules":[
									_vm.rules.required,
									_vm.rules.minMaxLength(1, 40),
									_vm.noSameName,
								],"label":"Name"},model:{value:(_vm.textField),callback:function ($$v) {_vm.textField=$$v},expression:"textField"}})],1),_c('v-slide-y-transition',[(_vm.invalidForm)?_c('span',{staticClass:"error--text mb-0 pb-0"},[_vm._v(" At least one permission must be selected. ")]):_vm._e()]),_c('UserEditSections',{ref:"createSections",staticClass:"mt-2",class:{ invalidForm: _vm.invalidForm },attrs:{"readonly":false},on:{"change":(newVal) => {
									this.newPerms = newVal;
								}}})],1)],1),_c('v-card-actions',{staticClass:"stickyButtons2"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelCreate}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.validEdit,"color":"primary","text":""},on:{"click":_vm.unholyCrap}},[_vm._v(" Submit ")])],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"v-card--material pa-3"},[_c('CardHeading',{attrs:{"color":'secondary',"loading":_vm.loading,"loadingTitle":`Loading permission group...`,"title":_vm.selectedItem}}),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pa-0 pl-2",attrs:{"cols":"9","md":"4"}},[_c('v-autocomplete',{staticClass:"ml-2 mr-2 mr-md-3",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"label":"Groups","item-text":"name","items":_vm.list},on:{"change":_vm.rowClickHandle},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1),_c('v-col',{staticClass:"px-0 pt-0 pb-2 pb-md-4",attrs:{"cols":"3","md":"4"}},[(_vm.list.length > 0)?_c('ConfirmDialog2',{attrs:{"persistent":"","confirmBtnText":'Delete',"confirmBtnColor":'error',"cancelBtnColor":'kajot-text',"cancelBtnText":'Back',"shouldShow":true},on:{"confirm":_vm.deleteItem},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" This permission group will be deleted. "),_c('br'),_vm._v(" Do you wish to continue ? ")]},proxy:true},{key:"default",fn:function({ showConfirm }){return [(
										_vm._usrFlagsSome({
											key: 'management.groups.detail',
											val: _vm.permissions.DELETE,
										})
									)?_c('v-btn',{staticClass:"error mr-1 mr-md-3",attrs:{"fab":"","small":!_vm.isMobile,"x-small":_vm.isMobile},on:{"click":showConfirm}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,false,304659719)}):_vm._e(),(
								_vm._usrFlagsSome({
									key: 'management.groups.detail',
									val: _vm.permissions.CREATE,
								})
							)?_c('v-btn',{staticClass:"primary",attrs:{"fab":"","small":!_vm.isMobile,"x-small":_vm.isMobile},on:{"click":function($event){_vm.createDialog = true}}},[_c('v-icon',{staticClass:"pt-1"},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('UserEditSections',{ref:"groupSections",staticClass:"mx-2 mt-1",attrs:{"loading":_vm.loading,"usersPerms":_vm.detail.sections,"readonly":!_vm._usrFlagsSome({
							key: 'management.groups.detail',
							val: _vm.permissions.EDIT,
						})},on:{"change":(newVal) => {
							this.changedPerms = newVal;
						}}}),(
						_vm._usrFlagsSome({
							key: 'management.groups.detail',
							val: _vm.permissions.EDIT,
						})
					)?_c('v-card-actions',{staticClass:"stickyButtons"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.disableSubmit,"text":""},on:{"click":function($event){return _vm.$refs.groupSections.reset()}}},[_c('v-icon',{staticClass:"primary--text mr-1",attrs:{"fab":"","small":""}},[_vm._v(" mdi-restore ")]),_vm._v(" Reset ")],1),_c('v-btn',{attrs:{"disabled":_vm.disableSubmit,"text":""},on:{"click":_vm.submitGroup}},[_vm._v(" Submit ")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }